import * as React from "react";
import Home from "../Home";
import Projects from "../Projects";

const Homepage = () => {
  return (
    <>
      <Home />
      <Projects />
    </>
  );
};

export default Homepage;
