import * as React from "react";
import { Typography } from "@mui/material";

const Patience = () => {
  return (
    <>
      <br />
      <Typography variant="h2" align="center">
        Patience is a virtue....Coming soon ;)
      </Typography>
    </>
  );
};

export default Patience;
